import React from "react";
import theme from "theme";
import { Theme, Strong, Text, Box, Section, Button, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				NEXT LEVEL ENGLISH ACADEMY
			</title>
			<meta name={"description"} content={"Ми пропонуємо широкий вибір курсів: загальна англійська для початківців і просунутих, бізнес-англійська, підготовка до міжнародних іспитів, англійська для дітей та підлітків. "} />
			<meta property={"og:title"} content={"NEXT LEVEL ENGLISH ACADEMY"} />
			<meta property={"og:description"} content={"Ми пропонуємо широкий вибір курсів: загальна англійська для початківців і просунутих, бізнес-англійська, підготовка до міжнародних іспитів, англійська для дітей та підлітків. "} />
			<meta property={"og:image"} content={"https://images.unsplash.com/photo-1538708591342-2dcd6706e3b6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<link rel={"shortcut icon"} href={"https://images.unsplash.com/photo-1538708591342-2dcd6706e3b6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://images.unsplash.com/photo-1538708591342-2dcd6706e3b6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://images.unsplash.com/photo-1538708591342-2dcd6706e3b6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://images.unsplash.com/photo-1538708591342-2dcd6706e3b6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<link rel={"apple-touch-startup-image"} href={"https://images.unsplash.com/photo-1538708591342-2dcd6706e3b6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<meta name={"msapplication-TileImage"} content={"https://images.unsplash.com/photo-1538708591342-2dcd6706e3b6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
		</Helmet>
		<Components.QuarklycommunityKitCookieUsed background="--color-darkL1" />
		<Components.HeaderNew>
			<Override slot="text">
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Next Level English Academy
				</Strong>
			</Override>
			<Override slot="link" />
			<Override slot="quarklycommunityKitMenuOverride5" />
			<Override slot="quarklycommunityKitMenuOverride6" />
			<Override slot="quarklycommunityKitMenuOverride4" />
		</Components.HeaderNew>
		<Section padding="120px 0 120px 0" background="linear-gradient(340deg,rgba(163, 103, 177, 0.29) 1%,rgba(57, 0, 0, 0.97) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/66a24c319710450018a192c7/images/English.jpg?v=2024-07-25T13:57:39.702Z) center/cover repeat scroll padding-box" md-padding="80px 0 90px 0" quarkly-title="Headline-3">
			<Override slot="SectionContent" sm-min-width="280px" />
			<Text margin="0px 0px 20px 0px" font="normal 700 52px/1.2 --fontFamily-sans" color="--light" sm-font="normal 700 42px/1.2 --fontFamily-sans">
				Welcome to Next Level English Academy!
			</Text>
			<Text
				margin="0px 30% 40px 0px"
				font="--lead"
				color="--light"
				lg-margin="0px 20% 50px 0px"
				md-margin="0px 0 50px 0px"
				border-color="--color-lightD1"
			>
				We created this school to help you achieve your language goals and unlock new opportunities. Our mission is to provide quality education and support for every student, regardless of their level of knowledge and goals. We believe that learning English can be an interesting and exciting process, and we are here to help you with that.
			</Text>
			<Box
				min-width="10px"
				min-height="10px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="36px 34px"
				md-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
			>
				<Box min-width="10px" min-height="10px">
					<Text margin="0px 0px 10px 0px" font="normal 600 30px/1.2 --fontFamily-sans" color="--light">
						12+
					</Text>
					<Text margin="0px 0px 0px 0px" color="#c5cdd7" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" border-color="#EDF2F6">
						<Strong
							color="--lightD1"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							years on the market
						</Strong>
					</Text>
				</Box>
				<Box min-width="10px" min-height="10px">
					<Text margin="0px 0px 10px 0px" font="normal 600 30px/1.2 --fontFamily-sans" color="--light">
						6+
					</Text>
					<Text margin="0px 0px 0px 0px" color="#c5cdd7" font="normal 300 18px/1.5 --fontFamily-sansHelvetica">
						<Strong
							color="--lightD1"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							types of courses
						</Strong>
					</Text>
				</Box>
				<Box min-width="10px" min-height="10px">
					<Text margin="0px 0px 10px 0px" font="normal 600 30px/1.2 --fontFamily-sans" color="--light">
						1200+
					</Text>
					<Text margin="0px 0px 0px 0px" color="#c5cdd7" font="normal 300 18px/1.5 --fontFamily-sansHelvetica">
						<Strong
							color="--lightD1"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							happy students
						</Strong>
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="90px 0 120px 0" background="#ffffff" quarkly-title="Team-15">
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				grid-template-columns="repeat(1, 1fr)"
				grid-template-rows="repeat(2, 1fr)"
				grid-gap="46px 24px"
				md-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-rows="auto"
				sm-grid-template-columns="1fr"
				flex-direction="column"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" color="--dark" font="--headline2">
						Advantages of our school
					</Text>
					<Text margin="0px 0px 0px 0px" color="--dark" font="--base">
						At Next Level English Academy, we offer an individual approach to each student, highly qualified teachers with many years of experience, modern teaching methods, and a friendly atmosphere. Our programs are designed to meet the needs of students of all ages and skill levels. Additionally, we offer the option to study online or offline, allowing you to choose the most convenient format for your learning.
					</Text>
				</Box>
				<Button
					margin="2rem 0px 0px 0px"
					type="link"
					href="/about"
					text-decoration-line="initial"
					text-align="center"
				>
					Learn more about us
				</Button>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://uploads.quarkly.io/66a24c319710450018a192c7/images/learning-English.jpg?v=2024-07-25T13:57:39.708Z"
						display="block"
						width="100%"
						height="400px"
						object-fit="cover"
						margin="0px 0px 30px 0px"
						sm-height="350px"
						border-radius="50px"
						object-position="top"
						srcSet="https://smartuploads.quarkly.io/66a24c319710450018a192c7/images/learning-English.jpg?v=2024-07-25T13%3A57%3A39.708Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66a24c319710450018a192c7/images/learning-English.jpg?v=2024-07-25T13%3A57%3A39.708Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66a24c319710450018a192c7/images/learning-English.jpg?v=2024-07-25T13%3A57%3A39.708Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66a24c319710450018a192c7/images/learning-English.jpg?v=2024-07-25T13%3A57%3A39.708Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66a24c319710450018a192c7/images/learning-English.jpg?v=2024-07-25T13%3A57%3A39.708Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66a24c319710450018a192c7/images/learning-English.jpg?v=2024-07-25T13%3A57%3A39.708Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66a24c319710450018a192c7/images/learning-English.jpg?v=2024-07-25T13%3A57%3A39.708Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Text margin="0px 0px 15px 0px" color="--dark" font="--headline2">
						Our Courses
					</Text>
					<Text margin="0px 0px 0px 0px" color="--dark" font="--base">
						We offer a wide range of courses: general English for beginners and advanced learners, business English, preparation for international exams, English for children and teenagers. Each course has a clear structure and includes interactive lessons, homework, and regular tests to assess progress. Our teachers are always ready to help and support you at every stage of your learning.
					</Text>
				</Box>
				<Button
					margin="2rem 0px 0px 0px"
					type="link"
					href="/team"
					text-decoration-line="initial"
					text-align="center"
				>
					Learn more about our services
				</Button>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://uploads.quarkly.io/66a24c319710450018a192c7/images/nbcQQYuvdAiNlB4tJQFqPnst6x8BaUYV.jpg?v=2024-07-25T13:57:39.712Z"
						display="block"
						width="100%"
						height="400px"
						object-fit="cover"
						margin="0px 0px 30px 0px"
						sm-height="350px"
						border-radius="50px"
						object-position="center"
						srcSet="https://smartuploads.quarkly.io/66a24c319710450018a192c7/images/nbcQQYuvdAiNlB4tJQFqPnst6x8BaUYV.jpg?v=2024-07-25T13%3A57%3A39.712Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66a24c319710450018a192c7/images/nbcQQYuvdAiNlB4tJQFqPnst6x8BaUYV.jpg?v=2024-07-25T13%3A57%3A39.712Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66a24c319710450018a192c7/images/nbcQQYuvdAiNlB4tJQFqPnst6x8BaUYV.jpg?v=2024-07-25T13%3A57%3A39.712Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66a24c319710450018a192c7/images/nbcQQYuvdAiNlB4tJQFqPnst6x8BaUYV.jpg?v=2024-07-25T13%3A57%3A39.712Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66a24c319710450018a192c7/images/nbcQQYuvdAiNlB4tJQFqPnst6x8BaUYV.jpg?v=2024-07-25T13%3A57%3A39.712Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66a24c319710450018a192c7/images/nbcQQYuvdAiNlB4tJQFqPnst6x8BaUYV.jpg?v=2024-07-25T13%3A57%3A39.712Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66a24c319710450018a192c7/images/nbcQQYuvdAiNlB4tJQFqPnst6x8BaUYV.jpg?v=2024-07-25T13%3A57%3A39.712Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Text margin="0px 0px 15px 0px" color="--dark" font="--headline2">
						Our Team
					</Text>
					<Text margin="0px 0px 0px 0px" color="--dark" font="--base">
						Our team consists of highly qualified teachers with many years of experience teaching English. Each teacher undergoes a thorough selection process and continuously improves their qualifications. We believe that the key to successful learning is a professional and individual approach to each student.
					</Text>
				</Box>
				<Button
					margin="2rem 0px 0px 0px"
					type="link"
					href="/about"
					text-decoration-line="initial"
					text-align="center"
				>
					Learn more about us
				</Button>
			</Box>
		</Section>
		<Components.Faq>
			<Override slot="text1">
				What documents are required for admission?
			</Override>
			<Override slot="text2">
				You do not need any special documents to enroll in the course. All you need is a desire to learn English and a willingness to spend time studying. We offer a trial class, after which you can make a final decision about admission.
			</Override>
			<Override slot="text3">
				How do online classes work?
			</Override>
			<Override slot="text4">
				Our online classes are conducted using modern video conferencing platforms. You will be able to study with the teacher at a time convenient for you and receive all the necessary educational materials in electronic form. We guarantee quality education and support during the entire course of study.
			</Override>
			<Override slot="text5">
				How to sign up for a course?
			</Override>
			<Override slot="text6">
				To sign up for the course, you need to contact our managers by phone or e-mail indicated on the site. They will help you choose the course that best suits your needs and sign you up for a trial class. You can also fill out the online form on our website and we will contact you to clarify the details.
			</Override>
		</Components.Faq>
		<Components.NewFooter1>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="text" />
			<Override slot="link2" />
		</Components.NewFooter1>
	</Theme>;
});